var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "material_upload",
      style:
        this.$route.path === "/material_upload"
          ? "padding:86px 94px 114px 65px;height:100%"
          : "padding:0;height:0"
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.$route.path === "/material_upload",
              expression: "this.$route.path === '/material_upload'"
            }
          ],
          staticClass: "before_upload"
        },
        [
          _c(
            "div",
            {
              class: [
                "upload_area",
                _vm.fileList.length ? "upload_after" : "upload_before"
              ],
              attrs: { id: "uploadArea" }
            },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload_outer",
                  class: _vm.uploadFlag == 0 ? "" : "upload_box",
                  style: _vm.fileList.length ? "padding-bottom: 40px;" : "",
                  attrs: {
                    action: "#",
                    "list-type": "picture-card",
                    "file-list": _vm.fileList,
                    multiple: "",
                    "with-credentials": true,
                    "on-change": _vm.uploadChange,
                    accept:
                      ".jpg,.jpeg,.png,.PNG,.gif,.JPG,.JPEG,.GIF,.bmp,.BMP,.TIF,.tif,.swf,.SWF.mp4,.MP4,.ogg,.flv,.avi,.wmv,.rmvb,.webm,.mov,.mkv,.mpg,.rm,.RM,.asf,.ASF",
                    "auto-upload": false
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "file",
                      fn: function(ref) {
                        var file = ref.file
                        return _c(
                          "div",
                          {},
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  "materialPreview",
                                  _vm.uploadFlag == 0 ? "file_mask_outer" : ""
                                ]
                              },
                              [
                                _vm.uploadFlag == 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "el-upload-list__item-actions",
                                        staticStyle: { "z-index": "10" }
                                      },
                                      [
                                        !_vm.disabled
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "el-upload-list__item-delete",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleRemove(
                                                      file
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-delete"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                file.status == "error"
                                  ? _c(
                                      "span",
                                      { staticClass: "error_upload" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "iconfont icon-sucaichuanshu-quxiao"
                                        }),
                                        _vm._v("上传失败")
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _c("el-progress", {
                              class: _vm._f("progressStatus")(file.status),
                              attrs: {
                                percentage: file.uploadProgress,
                                "show-text": false
                              }
                            }),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: file.name,
                                  placement: "bottom-start",
                                  "popper-class": "upload_tooltip"
                                }
                              },
                              [
                                _c("div", { staticClass: "file_name" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(file.name.slice(0, 9)) +
                                      _vm._s(
                                        file.name.length > 9 ? "..." : ""
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "file_size" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("transformByte")(file.size)) +
                                  " "
                              )
                            ])
                          ],
                          1
                        )
                      }
                    }
                  ])
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default"
                  }),
                  _vm.fileList.length
                    ? _c("div", { staticClass: "choose_material" }, [
                        _vm._v("添加图片或视频")
                      ])
                    : _vm._e()
                ]
              ),
              !_vm.fileList.length
                ? _c("div", { staticClass: "choose_material" }, [
                    _vm._v("选择本地图片或视频")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "upload_bottom" }, [
            _c("div", { staticClass: "bottom_left" }, [
              _vm._v("已选择" + _vm._s(_vm.fileList.length) + "个图片和视频 "),
              _c("span", { staticStyle: { "margin-left": "20px" } }, [
                _vm._v("上传时将自动为您过滤已上传或重复的素材")
              ]),
              _c("span", [_vm._v("，上传完成前请勿关闭此页面")])
            ]),
            _c(
              "div",
              { staticClass: "bottom_right" },
              [
                _c("span", { staticStyle: { "margin-right": "10px" } }, [
                  _vm._v("谁可以看")
                ]),
                _c(
                  "el-select",
                  {
                    class: _vm.fileList.length
                      ? this.uploadFlag == 1
                        ? "opacity"
                        : ""
                      : "opacity",
                    attrs: {
                      placeholder: "请选择",
                      size: "mini",
                      "popper-append-to-body": false,
                      disabled: _vm.fileList.length
                        ? this.uploadFlag == 1
                          ? true
                          : false
                        : true
                    },
                    on: { change: _vm.setPermissions },
                    model: {
                      value: _vm.viewTypeValue,
                      callback: function($$v) {
                        _vm.viewTypeValue = $$v
                      },
                      expression: "viewTypeValue"
                    }
                  },
                  _vm._l(_vm.options, function(item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value }
                    })
                  }),
                  1
                ),
                _vm.uploadFlag == 0
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          disabled: !_vm.fileList.length
                        },
                        on: { click: _vm.handleUpload }
                      },
                      [_vm._v("开始上传")]
                    )
                  : _vm._e(),
                _vm.uploadFlag == 1
                  ? _c(
                      "el-button",
                      {
                        staticClass: "cancel_upload_sty",
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.cancelAllFile }
                      },
                      [_vm._v("取消上传")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.viewTypeValue == "3" ? "部分可见" : "不给谁看",
                visible: _vm.setPermissionsDialog,
                width: "382px",
                top: "30vh",
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "custom-class": "permission-dialog",
                "show-close": true,
                "before-close": _vm.downloadClose
              },
              on: {
                "update:visible": function($event) {
                  _vm.setPermissionsDialog = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "search_tree" },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "输入关键字",
                      "suffix-icon": "el-icon-search",
                      size: "mini"
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText"
                    }
                  }),
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "filter-tree",
                    attrs: {
                      data: _vm.dataSee,
                      "show-checkbox": "",
                      "default-expand-all": "",
                      "node-key": "id",
                      "highlight-current": "",
                      props: _vm.defaultProps,
                      "filter-node-method": _vm.filterNode
                    },
                    on: { check: _vm.checkTree }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.ensurePermission }
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.cancelPermission } }, [
                    _vm._v("取 消")
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }