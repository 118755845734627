var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-aside",
        { attrs: { width: "255px" } },
        [
          _c("div", { staticClass: "logoContainer" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("@/assets/images/side-logo.png"), alt: "" }
            })
          ]),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "material_upload_btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.goUpload }
                },
                [
                  _vm.setImgUploading
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/images/uploading.gif"),
                          alt: ""
                        }
                      })
                    : _vm._e(),
                  !_vm.setImgUploading
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/images/upload.png"),
                          alt: ""
                        }
                      })
                    : _vm._e(),
                  _c("span", [_vm._v("上传")])
                ]
              )
            ],
            1
          ),
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo",
              attrs: {
                "text-color": "rgba(255, 255, 255, 0.9)",
                "active-text-color": "rgba(255, 255, 255, 0.9)",
                "background-color": "#161626",
                router: "",
                "default-active": _vm.$route.path
              },
              on: { select: _vm.clickEvent }
            },
            [
              _c("el-menu-item", { attrs: { index: "/allrourse" } }, [
                _c("img", {
                  staticClass: "upload",
                  staticStyle: { width: "20px", height: "20px" },
                  attrs: {
                    src: require("@/assets/images/all-rourse.png"),
                    alt: ""
                  }
                }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("全部素材")
                ])
              ]),
              _c("el-menu-item", { attrs: { index: "/my_collection" } }, [
                _c("img", {
                  staticClass: "upload",
                  staticStyle: { width: "20px", height: "20px" },
                  attrs: {
                    src: require("@/assets/images/my-store.png"),
                    alt: ""
                  }
                }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("我的收藏")
                ])
              ]),
              _c("el-menu-item", { attrs: { index: "/my_material" } }, [
                _c("img", {
                  staticClass: "upload",
                  staticStyle: { width: "20px", height: "20px" },
                  attrs: {
                    src: require("@/assets/images/my-rourse.png"),
                    alt: ""
                  }
                }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("我的素材")
                ])
              ]),
              _c("el-menu-item", { attrs: { index: "/uploading" } }, [
                _c("img", {
                  staticClass: "upload",
                  staticStyle: { width: "20px", height: "20px" },
                  attrs: {
                    src: require("@/assets/images/rourse-send.png"),
                    alt: ""
                  }
                }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("上传记录")
                ])
              ]),
              _c(
                "el-submenu",
                { attrs: { index: "5" } },
                [
                  _c("template", { slot: "title" }, [
                    _c("img", {
                      staticClass: "upload",
                      staticStyle: { width: "20px", height: "20px" },
                      attrs: {
                        src: require("@/assets/images/label-manager.png"),
                        alt: ""
                      }
                    }),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v("标签管理")
                    ])
                  ]),
                  _c("el-menu-item", { attrs: { index: "/tag" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "ing",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [_vm._v("标签")]
                    )
                  ]),
                  _c("el-menu-item", { attrs: { index: "/tag_group" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "ing",
                        attrs: { slot: "title" },
                        slot: "title"
                      },
                      [_vm._v("标签组")]
                    )
                  ])
                ],
                2
              ),
              _c("el-menu-item", { attrs: { index: "/recycle" } }, [
                _c("img", {
                  staticClass: "upload",
                  staticStyle: { width: "20px", height: "20px" },
                  attrs: { src: require("@/assets/images/waste.png"), alt: "" }
                }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("回收站")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-container",
        { staticStyle: { height: "100vh", overflow: "hidden" } },
        [
          _c("el-header", { attrs: { height: "52px" } }, [
            _c("div", { staticClass: "head" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("智能化多媒体管理和生产系统")
              ]),
              _c(
                "div",
                { staticClass: "user" },
                [
                  _c(
                    "el-dropdown",
                    {
                      attrs: { trigger: "click", placement: "bottom-start" },
                      on: { command: _vm.handleCommand }
                    },
                    [
                      _c("span", { staticClass: "el-dropdown-link" }, [
                        _vm._v(" " + _vm._s(_vm.name)),
                        _c("i", {
                          staticClass: "el-icon-caret-bottom",
                          staticStyle: { "padding-left": "5px" }
                        })
                      ]),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "header_dropdown",
                          attrs: { slot: "dropdown" },
                          slot: "dropdown"
                        },
                        [
                          _c("el-dropdown-item", { attrs: { command: "a" } }, [
                            _vm._v("个人信息")
                          ]),
                          _c("el-dropdown-item", { attrs: { command: "b" } }, [
                            _vm._v("退出登录")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "el-main",
            [
              _vm.isRouterAlive ? _c("router-view") : _vm._e(),
              _c("global-uploader"),
              this.$route.path !== "/watchlist"
                ? _c("div", [_c("ToWatchList")], 1)
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }