<template>
  <div class="material_upload" :style="this.$route.path === '/material_upload' ?'padding:86px 94px 114px 65px;height:100%':'padding:0;height:0'">
  <!-- 文件选择 -->
  <div class="before_upload"  v-show="this.$route.path === '/material_upload'">
    <div :class="['upload_area',fileList.length?'upload_after': 'upload_before']" id="uploadArea">
      <el-upload
        class="upload_outer"
        :style="fileList.length?'padding-bottom: 40px;':''"
        action="#"
        list-type="picture-card"
        :file-list="fileList"
        multiple
        ref="upload"
        :with-credentials="true"
        :on-change="uploadChange"
        :class="uploadFlag == 0?'':'upload_box'"
        accept=".jpg,.jpeg,.png,.PNG,.gif,.JPG,.JPEG,.GIF,.bmp,.BMP,.TIF,.tif,.swf,.SWF.mp4,.MP4,.ogg,.flv,.avi,.wmv,.rmvb,.webm,.mov,.mkv,.mpg,.rm,.RM,.asf,.ASF"
        :auto-upload="false">
          <i slot="default" class="el-icon-plus"></i>
          <div class="choose_material" v-if="fileList.length">添加图片或视频</div>
          <div slot="file" slot-scope="{file}">
            <div :class="['materialPreview',uploadFlag == 0 ? 'file_mask_outer':'']">
              <span class="el-upload-list__item-actions" style="z-index:10" v-if="uploadFlag == 0">
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
              <span class="error_upload" v-if="file.status == 'error'"><i class="iconfont icon-sucaichuanshu-quxiao"></i>上传失败</span>
            </div>
            <el-progress :percentage="file.uploadProgress" :show-text="false" :class="file.status | progressStatus"></el-progress>
            <!-- {{file.status}} -->
            <el-tooltip class="item" effect="dark" :content="file.name" placement="bottom-start" popper-class="upload_tooltip">
              <div class="file_name">
                {{file.name.slice(0,9)}}{{file.name.length>9?'...':''}}
              </div>
            </el-tooltip>
            <div class="file_size">
              {{file.size | transformByte}}
            </div>
          </div>
      </el-upload>
      <div class="choose_material" v-if="!fileList.length">选择本地图片或视频</div>
    </div>
    <div class="upload_bottom">
      <div class="bottom_left">已选择{{fileList.length}}个图片和视频  <span style="margin-left:20px">上传时将自动为您过滤已上传或重复的素材</span><span>，上传完成前请勿关闭此页面</span> </div>
      <div class="bottom_right">
        <span style="margin-right:10px;">谁可以看</span>
        <el-select v-model="viewTypeValue" placeholder="请选择" size="mini" :popper-append-to-body="false" @change="setPermissions" :disabled="fileList.length?(this.uploadFlag == 1 ?true:false):true" :class="fileList.length?(this.uploadFlag == 1 ?'opacity':''):'opacity'">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-button size="mini" @click="handleUpload" type="primary" :disabled="!fileList.length" v-if="uploadFlag == 0">开始上传</el-button>
        <el-button size="mini" @click="cancelAllFile" type="primary" class="cancel_upload_sty" v-if="uploadFlag == 1">取消上传</el-button>
      </div>
    </div>

    <el-dialog
      :title="viewTypeValue == '3'?'部分可见':'不给谁看'"
      :visible.sync="setPermissionsDialog"
      width="382px"
      top="30vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="permission-dialog"
      :show-close="true"
      :before-close="downloadClose">
      <div class="search_tree">
          <el-input
            placeholder="输入关键字"
            suffix-icon="el-icon-search"
            size="mini"
            v-model="filterText">
          </el-input>
          <el-tree
            class="filter-tree"
            :data="dataSee"
            show-checkbox
            default-expand-all
            node-key="id"
            ref="tree"
            highlight-current
            :props="defaultProps"
            @check="checkTree"
            :filter-node-method="filterNode">
          </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ensurePermission">确 定</el-button>
        <el-button @click="cancelPermission">取 消</el-button>
      </span>
    </el-dialog>
  </div>

  </div>
</template>
<script>
import $ from 'jquery'
import noResult from '../component/noResult.vue'

import axios, { CancelToken } from 'axios';
var instance = axios.create({});

var chunkSize = 5 * 1024 * 1024; // 切片大小
var fileIndex = 0; // 当前正在被遍历的文件下标

// 所有文件状态
const Status = {
  wait: 'wait',
  pause: 'pause',
  uploading: 'uploading',
  hash: 'hash',
  error: 'error',
  done: 'done',
  cancel: 'cancel'
};

// 单个文件的状态
const fileStatus = {
  wait: 'wait',
  uploading: 'uploading',
  success: 'success',
  error: 'error',
  secondPass: 'secondPass',
  pause: 'pause',
  resume: 'resume',
  cancel: 'cancel'
};
// 单个文件的状态 对应描述
const fileStatusStr = {
  wait: '待上传',
  uploading: '上传中',
  success: '上传成功',
  error: '上传失败',
  secondPass: '已秒传',
  pause: '已暂停',
  resume: '恢复',
  cancel: '已取消'
};
export default {
  name: 'MaterUpload',
  filters: {
    // 单个文件状态格式化
    fileStatus(status) {
      return fileStatusStr[fileStatus[status]];
    },
    progressStatus(status){
      switch(status){
        case 'ready':
          return 'progress_grey'
        case 'pause':
          return 'progress_grey'
        case 'uploading':
        case 'resume':
        case 'success':
        case 'secondPass':
          return 'progress_blue'
        case 'error':
          return 'progress_error'
      }
    }
  },
  components: {
      noResult,
  },
  inject:['reload'],
  data() {
    return {
      setPermissionsDialog: false,
      options: [{
          value: '1',
          label: '公开'
        }, {
          value: '2',
          label: '仅自己可见'
        }, {
          value: '3',
          label: '部分可见'
        }, {
          value: '4',
          label: '不给谁看'
      }],
      viewTypeValue: '1',
      disabled: false,
      filterText: '',
      dataSee: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      fileList:[],
      departmentSeeIds:[],
      viewTypeOld:"",

      worker: null,
      cancels: [], // 存储要取消的请求
      tempThreads: 100,  //上传并发数
      // 默认状态
      status: Status.wait,
      uploadFlag: 0, //上传按钮变化的标志
      ifCancel: false, //是否点击了取消上传 为了防止hash值生成之前点击了取消上传出现的问题
      cancelIdString:[],
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    viewTypeValue(newVal,oldVal) {
      this.viewTypeOld = oldVal
      // console.log(newVal,oldVal)
    },
    '$route': function (to, from) {
        if(to.path == '/material_upload'){
          this.viewTypeValue = '1'
          this.departmentSeeIds = []
          this.filterText = ''
        }
　　  },
    fileList() {
      this.fileList.length == 0 ? this.uploadFlag = 0:''
    },
  },
  destroyed() {
    this.clearFiles();
  },
  created() {
    this.setAxios();
  },
  mounted() {
    this.getDepart()
  },
  methods: {
    downloadClose(done) {
      this.setPermissionsDialog = false
    },
    setPermissions(value) {
      // console.log(value)
      this.departmentSeeIds = []
      if(value == '3' || value == '4'){
        // this.resetChecked()
        this.$refs.tree?this.$refs.tree.setCheckedKeys([]):'';
        this.setPermissionsDialog = true
      }
    },
    ensurePermission() {
      this.setPermissionsDialog = false
      this.filterText = ''
    },
    cancelPermission() {
      this.setPermissionsDialog = false
      this.viewTypeValue = this.viewTypeOld //(如果点了弹框的取消的话,还是保留在上一个状态)
      this.filterText = ''
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    checkTree(now,all) {
      // console.log(now,all)
      this.departmentSeeIds = all.checkedKeys
      all.checkedKeys?all.checkedNodes.forEach((item1,j)=>{
        JSON.stringify(item1.children)?this.departmentSeeIds.splice(j,1):''
      }):''

      // console.log(this.departmentSeeIds)
    },
    handleRemove(file, fileList) {
      let uploadFiles = this.$refs.upload.fileList
      for (var i = 0; i < uploadFiles.length; i++) {
      if (uploadFiles[i]['url'] == file.url) {
        uploadFiles.splice(i, 1)
      }
      }
    },
    uploadChange(file, fileList) {
      this.fileList = fileList //赋值操作
      this.$nextTick(()=>{ //动态添加图片与视频
        $(".materialPreview").each((i,item)=>{
          // console.log(fileList[i].raw.type)
          const isIMG = fileList[i].raw.type === 'image/jpg' || fileList[i].raw.type === 'image/jpeg' || fileList[i].raw.type === 'image/png' || fileList[i].raw.type === 'image/gif' || fileList[i].raw.type === 'image/bmp' || fileList[i].raw.type === 'image/tiff'
          if(isIMG){ //图片
          if(fileList[i].raw.type != 'image/tiff'){ //tiff不支持，特殊处理
            let imgElement = document.createElement("img");
            imgElement.setAttribute('src', fileList[i].url);
            imgElement.setAttribute('class', 'el-upload-list__item-thumbnail');
            $(imgElement).css({'max-height':'94px','max-width':'100%','width':'auto','height':'auto','flex-shrink':'0'})
            if($(item).children().last()[0].nodeName !== "IMG" && !$(item).find("video").length){
              $(item).append(imgElement)
            }
          }else{
              $(item).append('<span class="noSurportInfo">暂不支持该格式文件预览</span>')
          }
        }else{ //视频
            let videoElement = document.createElement("video")
            videoElement.setAttribute('src', fileList[i].url)
            videoElement.setAttribute('width', '100%')
            $(videoElement).css('max-height', '94px')
            let videoMask = '<div class="file_mask"><i class="iconfont icon-shipin"></i></div>'
            if($(item).children().last()[0].nodeName !== "IMG" && !$(item).find("video").length){
              $(item).append(videoElement)
              $(item).append(videoMask)
              if(fileList[i].raw.type != 'video/mp4' && fileList[i].raw.type != 'video/webm' && fileList[i].raw.type != 'video/quicktime' && fileList[i].raw.type != 'video/x-matroska' || fileList[i].name.substring(fileList[i].name.lastIndexOf('.')) ==".MP4"){
                $(item).append('<span class="noSurportInfo">暂不支持该格式文件预览</span>')
              }
            }
          }
        })
      })
    },
   async handleUpload (flag) {
      // console.log(flag)
      this.uploadFlag = 1
      this.ifCancel = false 
      this.$store.commit('setImgUploading', true)
      this.getUploadList('finish')
      // console.log('handleUpload -> this.fileList', this.fileList);
      if (!this.fileList) return;
      // this.status = Status.uploading;
      const filesArr = this.fileList;
      // console.log(filesArr.length)
      // console.log(filesArr)
      for (let i = 0; i < filesArr.length; i++) {
        // console.log(i)
        // console.log(filesArr[i].status)
        fileIndex = i;
        if (['secondPass', 'success', 'error','cancel',].includes(filesArr[i].status)) {
          // console.log('跳过已上传成功或已秒传的或失败的或取消上传的');
          continue;
        }
        if(flag == 'pause'){
          if (['pause',].includes(filesArr[i].status)) {
            // console.log('跳过暂停的的');
            continue;
          }
        }
        const fileChunkList = this.createFileChunk(filesArr[i]); //给filesArr的每一个文件创建切片，fileChunkList为每个文件切片数组集合
        // hash校验，是否为秒传
        // 若不是恢复，再进行hash计算
        if (filesArr[i].status !== 'resume') {
          this.status = Status.hash;
          filesArr[i].hash = await this.calculateHash(fileChunkList); //hash值生成
          // 若清空或者状态为等待，则跳出循环
          if (this.status === Status.wait) {
            // console.log('若清空或者状态为等待，则跳出循环');
            break;
          }

          // console.log('handleUpload -> hash', filesArr[i].hash);
        }
        this.status = Status.uploading;

        this.cancelIdString.push(filesArr[i].hash) // 全部取消的hash值数组
        //如果是恢复上传不走hash校验
        if(filesArr[i].status == 'resume'){
          // console.log('开始上传文件----》', filesArr[i].name);
          filesArr[i].status = fileStatus.uploading;
          // const getChunkStorage = this.getChunkStorage(filesArr[i].hash);
          filesArr[i].fileHash = filesArr[i].hash; // 文件的hash，合并时使用
          filesArr[i].chunkList = fileChunkList.map(({ file }, index) => ({
            fileHash: filesArr[i].hash,
            fileName: filesArr[i].name,
            index,
            hash: filesArr[i].hash + '-' + index,
            chunk: file,
            size: file.size,
            uploaded: false, // 标识：是否已完成上传
            progress: 0,
            status:'wait' // 上传状态，用作进度状态显示
          }));

          this.$set(filesArr, i, filesArr[i]);
          // console.log('handleUpload ->  this.chunkData', filesArr[i]);
          await this.uploadChunks(filesArr[i]);
        }else{
          if(!this.ifCancel){
            const verifyRes = await this.verifyUpload(filesArr[i].name, filesArr[i].hash);
            if (verifyRes.status) {
              filesArr[i].status = fileStatus.secondPass;
              filesArr[i].uploadProgress = 100;
              // 重复上传的做秒传处理直接移除列表
              // filesArr.splice(i,1)+
              setTimeout(()=>{
                $(".el-upload-list__item").each((j,item)=>{
                i==j ? $(item).css("display","none"):''
                })
              },100)
              // 重复上传的做秒传处理直接移除列表
              this.isAllStatus();
            } else {
              // console.log('开始上传文件----》', filesArr[i].name);
              filesArr[i].status = fileStatus.uploading;
              filesArr[i].fileHash = filesArr[i].hash; // 文件的hash，合并时使用
              filesArr[i].chunkList = fileChunkList.map(({ file }, index) => ({
                fileHash: filesArr[i].hash,
                fileName: filesArr[i].name,
                index,
                hash: filesArr[i].hash + '-' + index,
                chunk: file,
                size: file.size,
                uploaded: false, // 标识：是否已完成上传
                progress: 0,
                status:'wait' // 上传状态，用作进度状态显示
              }));

              this.$set(filesArr, i, filesArr[i]);
              // console.log('handleUpload ->  this.chunkData', filesArr[i]);
              await this.uploadChunks(filesArr[i]);
            }
          }
        }
      }
    },
    // 文件个数限制钩子
    fileLimitFn(files) {
      this.$message.warning(
        `最多支持选择${this.limit}个文件`
      );
    },
    // 清空文件，暂未使用
    clearFiles() {
      this.$refs.upload.clearFiles();
    },

    getDepart () {  //部门树接口

        this.$http({
          method: 'GET',
          url: '/vw_material/get_company_users/',
        }).then(res => {
          if (res.data.status === 0) {

              res.data.result.forEach((item,i)=>{

                let obj = {}
                let children = []

                item.users.length ? item.users.forEach((item1,j)=>{
                  let obj1 = {}
                  obj1.id = item1.user_id
                  obj1.label = item1.name
                  children.push(obj1)
                }):""

                obj.children = children
                obj.id = i+1
                obj.label = item.department
                obj.children.length == 0 ? obj.disabled = true:''

                this.dataSee.push(obj)
              })

          } else {
            this.error_msg = res.data.message
          }
        }).catch(error => console.log(error))


    },

        // 将切片传输给服务端
    async uploadChunks(data) {
      // console.log('uploadChunks -> data', data);
      var chunkData = data.chunkList;  //文件切片数组
      return new Promise(async (resolve, reject) => {
          // chunkData.filter(({ uploaded }) => !uploaded)    //筛选出还没有上传的视频片段
        let uploadFileType = ''
        if( data.raw.type === 'image/jpg' || data.raw.type === 'image/jpeg' || data.raw.type === 'image/png' || data.raw.type === 'image/gif' || data.raw.type === 'image/tiff' || data.raw.type === 'image/bmp') {
          uploadFileType = "2"
        }else{
          uploadFileType = "1"
        }
        const requestDataList = chunkData.filter(({ uploaded }) => !uploaded).map(({ fileHash, chunk, fileName, index}) => {
            const formData = new FormData();
            formData.append('task_id', fileHash); //获取文件的唯一标识
            formData.append('chunk',index) //获取该分片在所有分片中的序号(0开始)
            formData.append('total_chunks',data.chunkList.length) //总分片个数(1)
            // formData.append('duration',duration) //总时长(视频)，非必填项
            formData.append('file', chunk); //上传的文件
            formData.append('type',uploadFileType) //上传类型 1为视频，2为图片
            formData.append('total_size',data.size) //文件总大小
            formData.append('view_type',this.viewTypeValue) //可见类型，1-公开，2-自己可见，3-部分可见，4-不给谁看
            formData.append('user_ids',this.departmentSeeIds) //部分可见或者不可见人的id，以逗号分隔 (非必填)
            formData.append('format',data.name.substring(data.name.lastIndexOf('.') + 1)) //文件类型MP4,jpg
            formData.append('img_name',data.name) //文件名
            return { formData, index, fileName};
          });

        // console.log('uploadChunks -> requestDataList', requestDataList);
        try {
          // console.log(requestDataList)
          const ret = await this.sendRequest(requestDataList, chunkData,data);
          // console.log('uploadChunks -> chunkData', chunkData);
          // console.log('ret', ret);
        } catch (error) {
          // 上传有被reject的
          // console.log('服务异常，文件上传失败！请稍后重试！')
          this.$message.error('服务异常，文件上传失败！请稍后重试！');
          return;
        }

        // 合并切片
        const isUpload = chunkData.some((item) => item.uploaded === false);
        // console.log('created -> isUpload', isUpload);
        if (isUpload) {
          // console.log('存在失败的切片')
        } else {
          // 执行合并
          try {
            await this.mergeRequest(data);
            resolve();
          } catch (error) {
            reject();
          }
        }
      });

    },
     // 切片上传进度
    createProgresshandler(item) {
      // console.log('createProgresshandler -> item', item);
      return (p) => {
        item.progress = parseInt(String((p.loaded / p.total) * 100));
        this.fileProgress();
      };
    },
    // 文件总进度
    fileProgress() {
      const currentFile = this.fileList[fileIndex];;
      if (currentFile) {
        const uploadProgress = currentFile.chunkList.map((item) => item.size * item.progress).reduce((acc, cur) => acc + cur);
        const currentFileProgress = parseInt((uploadProgress / currentFile.size).toFixed(2));
        // 真假进度条处理--处理进度条后移
        if (!currentFile.fakeUploadProgress) {
          currentFile.uploadProgress = currentFileProgress;
          this.$set(this.fileList, fileIndex, currentFile);
        } else if (currentFileProgress > currentFile.fakeUploadProgress) {
          currentFile.uploadProgress = currentFileProgress;
          this.$set(this.fileList, fileIndex, currentFile);
        }
      }
    },

    // 并发处理
    async sendRequest(forms, chunkData, data) {
      // console.log('sendRequest -> forms', forms);
      // console.log('sendRequest -> chunkData', chunkData);
      var finished = 0;
      const total = forms.length;
      const that = this;
      // const retryArr = []; // 数组存储每个文件hash请求的重试次数，做累加 比如[1,0,2],就是第0个文件切片报错1次，第2个报错2次
      return new Promise((resolve, reject) => {
        const handler = () => {
          // console.log('handler -> forms', forms);
          if (forms.length) {
            // 出栈
            const formInfo = forms.shift();
            const formData = formInfo.formData;
            const index = formInfo.index;
            instance
              .post('/upload/upload_part/',formData, {
                onUploadProgress: that.createProgresshandler(chunkData[index]),
                cancelToken: new CancelToken((c) => this.cancels.push(c)),
                timeout: 0
              })
              .then((res) => {
                // console.log('handler -> res', res);
                // 更改状态
                chunkData[index].uploaded = true;
                chunkData[index].status = 'success';

                finished++;
                handler();
              })
              .catch((e) => {
                // 若状态为暂停或等待，则禁止重试
                // console.log('handler -> this.status', this.status);
                // data.status = 'ready'
                // this.status = Status.wait;
                data.status = 'error'
                this.status = Status.error;
                // 断网出错等，将文件状态改为error

                // if ([Status.pause, Status.wait, Status.cancel].includes(this.status)) return;

                // console.warn('出现错误', e);
                // // 上传有被reject的
                // console.log('handler -> retryArr', retryArr);
                // if (typeof retryArr[index] !== 'number') {
                //   retryArr[index] = 0;
                // }

                // // 更新状态
                // chunkData[index].status = 'warning';

                // // 累加错误次数
                // retryArr[index]++;

                // // 重试3次
                // if (retryArr[index] >= 3) {
                //   console.warn(' 重试失败--- > handler -> retryArr', retryArr, chunkData[index].hash);
                //   return reject('重试失败', retryArr);
                // }

                // console.log('handler -> retryArr[finished]', `${chunkData[index].hash}--进行第 ${retryArr[index]} '次重试'`);
                // console.log(retryArr);

                this.tempThreads++; // 释放当前占用的通道

                // 将失败的重新加入队列
                // forms.push(formInfo);
                handler();

              });
          }

          if (finished >= total) {
            resolve('done');
          }
        };

        // 控制并发
        for (let i = 0; i < this.tempThreads; i++) {
          handler();
        }

      });

    },
    // 通知服务端合并切片
    mergeRequest(data) {
      // console.log(data)
      return new Promise((resolve, reject) => {
        let arr = ['JPG','jpg','jpeg','JPEG','png','PNG','gif','GIF','bmp','BMP','tif','TIF','swf','SWF']
        let format = data.name.substring(data.name.lastIndexOf('.') + 1)
        let type =''
        arr.indexOf(format)>-1?type=2:type=1
        const formData = new FormData();
        formData.append("filename", data.name)
        formData.append("task_id", data.fileHash)
        formData.append("format", format)
        formData.append("type", type)
        instance
            .post('/upload/upload_success/', formData, {
              timeout: 0
            })
            .then((res) => {
              // 清除storage
              if (res.data.status === 0) {
                data.status = fileStatus.success;
                // console.log('mergeRequest -> data', data);
                // clearLocalStorage(data.fileHash);
                this.fileList.forEach((item,i)=>{
                  if(data.fileHash == item.fileHash){
                    $(".el-upload-list__item").each((j,item)=>{
                      i==j ? $(item).css("display","none"):''
                    })
                    // this.fileList.splice(i,1)
                  }
                })
                // 判断是否所有都成功上传
                this.isAllStatus();
                resolve();
              } else {
                 this.$message({
                  showClose: true,
                  message: '文件上传失败，请重新上传！',
                  type: 'error',
                  duration: 0
                 });

                data.status = fileStatus.error;
                this.status = Status.wait;
                // this.status = Status.error;
                // 文件块数量不对，清除缓存
                // clearLocalStorage(data.fileHash);
                resolve();
              }
            })
            .catch((err) => {
              // console.log('mergeRequest -> err', err);
              data.status = fileStatus.error;
              reject();
            });

      });
    },
    // 创建文件切片
    createFileChunk(file, size = chunkSize) {
      // console.log(file)
      // console.log(chunkSize)

      const fileChunkList = [];
      var count = 0;
      while (count < file.size) {
        fileChunkList.push({
          file: file.raw.slice(count, count + size)
        });
        count += size;
      }
      // console.log('createFileChunk -> fileChunkList', fileChunkList);
      return fileChunkList;
    },
        // 暂停上传
    handlePause(id,status) {
      // console.log(this.fileList.length)
      // console.log(fileIndex)
      this.status = Status.pause;
      if (this.fileList.length) {
        const currentFile = this.fileList[fileIndex];
        currentFile.status = fileStatus.pause;
        // this.$set(currentFile,status,fileStatus.pause)
        // 将当前进度赋值给假进度条
        currentFile.fakeUploadProgress = currentFile.uploadProgress;
        // console.log('handlePause -> currentFile', currentFile);
      }
      while (this.cancels.length > 0) {
        this.cancels.pop()('取消请求');
      }
      // if(status !='cancel' && this.fileList.length - 1 != fileIndex){ //当不是取消，文件也不是队列的最后一个时，点暂停要继续上传其他的
      //   this.handleUpload('pause') //还得继续传
      // }
    },

    // 恢复上传
    handleResume(id,task_id,show) {
      // this.getUploadStatus(task_id)
      this.status = Status.uploading;
      // console.log('handleResume -> this.fileList[fileIndex]', this.fileList[fileIndex]);
      this.fileList[fileIndex].status = fileStatus.resume;
      this.handleUpload();
      // this.$forceUpdate()
    },
    // 文件上传之前的校验： 校验文件是否已存在
    verifyUpload(fileName, fileHash) {
          return new Promise((resolve) => {
            const formData = new FormData()
            formData.append("task_id",fileHash)
            instance
              .post('/upload/check_file/', formData)
              .then((res) => {
                // console.log('verifyUpload -> res', res);
                   resolve(res.data);
              })
              .catch((err) => {
                // console.log('verifyUpload -> err', err);
              });
          });
    },
    // 生成文件 hash（web-worker）
    calculateHash(fileChunkList) {
      // console.log('calculateHash -> fileChunkList', fileChunkList);
        return new Promise((resolve) => {
          this.worker = new Worker('../hash.js');
          this.worker.postMessage({ fileChunkList });
          this.worker.onmessage = (e) => {
            const { percentage, hash } = e.data;
            if (this.fileList[fileIndex]) {
              this.fileList[fileIndex].hashProgress = Number(percentage.toFixed(0));
              this.$set(this.fileList, fileIndex, this.fileList[fileIndex]);
            }
            if (hash) {
              resolve(hash);
            }
          };
        });
    },
    // axios 设置axios参数
    setAxios() {
      if (!this.headers) return;
      for (const i in this.headers) {
        instance.defaults.headers.common[i] = this.headers[i];
      }

      // 是否携带cookie
      if (this.withCredentials) {
        instance.defaults.withCredentials = true;
      }

      // 设置baseUrl
      if (this.baseUrl) {
        // instance.defaults.baseURL = this.baseUrl;
        instance.defaults.baseURL = this.$http.defaults.baseURL
      }

      // 设置切片大小
      chunkSize = this.chunkSize;
    },
    // 清空文件
    clearFiles() {
      // console.log('清空文件');
      fileIndex = 0;
      this.handlePause();

      this.worker && this.worker.terminate(); // 中断worker
      this.status = Status.wait;

      Object.assign(this.$data, this.$options.data()); // 重置data所有数据
    },
    // 判断是否都已完成上传
    isAllStatus() {
      // flag 判断文件列表是否置空
      const isAllSuccess = this.fileList.every((item) => ['success', 'secondPass', 'error','cancel'].includes(item.status));
      // console.log('mergeRequest -> isAllSuccess', isAllSuccess);
      if (isAllSuccess) {
        this.status = Status.done;
        this.$emit('success');
        // if(flag){ //当文件是重复上传的不要刷新页面
          // 全传完了刷新一下页面
          this.fileList = []
          this.$store.commit("setImgUploading",false)
          // 全传完了刷新一下页面
        // }
        return true

      }else{
        // this.fileList.length?this.handleUpload():''
      }
    },
    cancelAllFile() {
      this.uploadFlag = 0
      this.ifCancel = true //点击了取消上传按钮
      this.handlePause()
      // console.log(this.cancelIdString)
      // console.log(this.cancelIdString.length)
      if(this.cancelIdString.length == 0){

      }else{
          const formData = new FormData()
          formData.append("task_id",this.cancelIdString.join(","))
          this.$http({
            method: 'POST',
            url: '/upload/cancel_the_upload/',
            data: formData
          }).then(res => {
            if (res.data.status === 0) {
              // console.log(this.fileList)
            }
          }).catch(error => console.log(error))
      }
    },

    //本页面必须调一次get_upload_list，为了更新已取消状态
    getUploadList (flag) {
       this.$http({
          method: 'GET',
          url: '/upload/get_upload_list/',
          params: {
            page: 1,  //页码，从1,开始
            page_num: 20,  //每页数量
            flag: flag == 'finish' ? 1:0
          }
        }).then(res => {
          if (res.data.status === 0) {
          } else {
          }
        }).catch(error => console.log(error))
    },

  }
}
</script>
<style lang="scss" scoped>
  .upload_outer{
    float: left;
  }
  .material_upload{
    overflow-y: auto;
    height: 100%;
    .opacity{
      opacity:0.6;
　　　filter:alpha(opacity=60);  //filter 过滤器   兼容IE678
    }
    .upload_area{
      width: 100%;
      height: 100%;
      border: 1px solid #3E3D4C;
      border-radius: 6px;
      overflow-y: auto;
      .choose_material{
        color: #2A9CFF;
        line-height: 14px;
      }
      /deep/.el-upload-list{
        .el-upload-list__item {
          transition: none !important;
          border: none;
        }
      }
      &::-webkit-scrollbar {
        width : 8px;
      }
      &::-webkit-scrollbar-thumb {
          border-radius: 7px;
          box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #B9BAC9;
      }
      &::-webkit-scrollbar-track {
          box-shadow: unset;
          background:#5E5D6E;
          border-radius: 7px;
      }
      @media screen and (min--moz-device-pixel-ratio:0) {
          scrollbar-width: thin;
      }
    }
    .upload_before{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      /deep/.el-upload{
          background-color: inherit;
          border: none;
          width: 50px;
          height: 68px;
          line-height: 68px;
          .el-icon-plus{
            color: #2A9CFF;
            font-size: 40px;
          }
        }
    }
    .upload_box{
      /deep/.el-upload--picture-card{
        display: none !important;
      }
    }
    .upload_after{
      .el-upload-list--picture-card .el-progress{
        position: relative;
        width: 100%;
        margin-top: 13px;
      }
      .progress_blue{
        /deep/.el-progress-bar__outer {
          background: rgba(42, 156, 255, 0.6);
        }
        /deep/.el-progress-bar__inner {
          background: #2A9CFF;
        }
      }
      .progress_grey{
        /deep/.el-progress-bar__outer {
          background: rgba(62, 61, 76, 0.5);
        }
        /deep/.el-progress-bar__inner {
          background: #33333f;
        }
      }
      .progress_error{
        /deep/.el-progress-bar__outer {
          background: rgba(211, 91, 91, 1);
        }
        /deep/.el-progress-bar__inner {
          background: rgba(211, 91, 91, 1);
        }
      }
      /deep/.el-upload-list__item{
        width: 170px;
        margin: 40px 0 0 15px;
        border-radius: inherit;
        float: left;
        height: auto;
        background-color: #0F0F1E;

        .materialPreview{
          width: 170px;
          height: 94px;
          background-color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .error_upload{
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: rgba(211, 91, 91, 0.6);
            color: rgba(255, 255, 255, 0.9);
            padding: 0px 3px;
            font-size: 12px;
            line-height: 20px;
            z-index: 99;
            display: flex;
            align-items: center;
          }
        }
        .file_mask_outer{
          &:hover{
            .file_mask{
              display: none;
            }
          }
        }

        .file_name{
          word-break: break-all;
          color: rgba(255, 255, 255, 0.9);
          font-size: 12px;
          margin-top: 10px;
        }
        .file_size{
          position: absolute;
          right: 0;
          bottom: 0;
          color: rgba(255, 255, 255, 0.9);
          font-size: 12px;
        }
        .file_mask{
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.41);
          color: rgba(255, 255, 255, 0.7);
          font-size: 20px;
        }
      }
      /deep/.el-upload{
        background-color: inherit;
        border: 1px solid #2A9CFF;
        width: 170px;
        height: 143px;
        line-height: inherit;
        margin-left: 15px;
        margin-top: 40px;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        float: left;

        .el-icon-plus{
          color: #2A9CFF;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      }

      video::-webkit-media-controls{
        display: none;
      }
    }
    .upload_bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(255,255,255,0.8);
      margin-top: 34px;
      .bottom_left{

      }
      .bottom_right{
        .el-select{
          width: 140px;
          margin-right: 68px;
          /deep/.el-select-dropdown{
            margin: 2px 0;
            background: #2D2D3D;
            border: inherit;
            border-radius: 2px;
            // border: 1px solid #5E5F72;
            .el-scrollbar{
              border-radius: 2px;
            }
            .el-select-dropdown__list{
              padding: 0;
              .el-select-dropdown__item.hover{
                background-color: #3E3D4C;
                color: #409EFF;
                font-weight: 700;
              }
            }
            .popper__arrow{
              display: none;
            }
          }
          /deep/input{
            background-color: #0F0F1E;
            border: 1px solid #5E5F72;
            color: rgba(255,255,255,0.8);
          }
        }
        .el-button--primary{
          color: rgba(255, 255, 255, 0.9);
          background-color: #2A9CFF;
          border-color: #2A9CFF;
          border-radius: 6px;
          padding: 7px 32px;
        }
        .el-button--primary.is-disabled{
          opacity: 0.6;
        }
        .cancel_upload_sty{
          background: #2B2A39;
          border-color: #2B2A39;
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }
</style>
<style lang="scss">
  .noSurportInfo{
      position: absolute;
      bottom: 5px;
      white-space: nowrap;
      color: #606266;
      left: 50%;
      transform: translate(-50%,0);
    }
  .el-dialog.permission-dialog{
      background-color: #292939;
      border-radius: 15px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
      padding: 8px 21px 21px;
      .el-dialog__header{
        text-align: center;
        padding: 0;
        padding-bottom: 6px;
        .el-dialog__title{
          color: rgba(255,255,255,0.9);
          font-size: 16px;
          letter-spacing: 3px;
        }
        .el-dialog__headerbtn{
          top: 10px;
          .el-dialog__close{
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .el-dialog__body{
        color: rgba(255,255,255,0.9);
        background-color: #0F0F1E;
        font-size: 14px;
        padding: 38px 62px 0 62px;
        .search_tree{
          .el-input{
            .el-input__inner{
              background-color: #0F0F1E;
              border: 1px solid #5E5F72;
              border-radius: 5px;
            }
            .el-input__icon{
              &::before{
                font-size: 18px;
                font-weight: bold;
                color: #fff;
              }
            }
          }
          .el-tree{
            background-color: #0F0F1E;
            border: 1px solid #5E5F72;
            margin-top: 15px;
            height: 220px;
            overflow-y: auto;
            scrollbar-width: thin;
            -ms-overflow-style:none;
            .el-tree-node{
              .el-tree-node__content{
                &:hover{
                   background-color: inherit;
                }
                .el-checkbox{
                  width: auto;
                  // margin-right: 11px;
                }
                .el-tree-node__label{
                  margin-left: 11px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 70%;
                  white-space: nowrap;
                  color: rgba(255,255,255,0.8);
                }
                &:hover{
                  background-color: #3E3D4C;
                  .el-tree-node__label{
                    // color: #409EFF;
                  }
                }
              }
              &.is-current{
                .el-tree-node__content{
                  background-color: inherit;
                }
              }
            }
            &::-webkit-scrollbar {
                width : 5px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 7px;
                box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: #B9BAC9;
            }
            &::-webkit-scrollbar-track {
                box-shadow: unset;
                background:#5E5D6E;
                border-radius: 7px;
                margin: 2px 0;
            }
            &::-webkit-scrollbar-button{
            }
            &::-webkit-scrollbar-button:vertical:start {
              width: 0;
              height: 0;
            }
            &::-webkit-scrollbar-button:vertical:end {
              width: 0;
              height: 0;
            }
            @media screen and (min--moz-device-pixel-ratio:0) {
                scrollbar-width: thin;
            }


          }
        }
      }
      .el-dialog__footer{
        padding: 20px 62px 36px 62px;
        background-color: #0F0F1E;
        border-radius: 0px 0px 10px 10px;
        .dialog-footer{
          display: flex;
          justify-content: space-between;
          .el-button{
            border-radius: 20px;
            border: 1px solid #5E5F72;
            background-color: inherit;
            color: rgba(255,255,255,0.9);
            padding: 6px 15px;
          }
        }
      }
  }
  .upload_tooltip.el-tooltip__popper{
    padding: 6px 10px;
    // top: 0 !important;
    max-width: 10%;
    // line-height: 18px;
  }
</style>
