var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "watch-container" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.$store.state.addSuccess,
                expression: "this.$store.state.addSuccess"
              }
            ],
            staticClass: "add-success"
          },
          [_vm._v(" 加入成功 ")]
        )
      ]),
      _c(
        "div",
        {
          staticClass: "watch-list-warp",
          on: {
            click: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.showWatchList($event)
            }
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show"
                }
              ],
              staticClass: "watch-list-abbr"
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPlayVideo,
                      expression: "isPlayVideo"
                    }
                  ],
                  staticClass: "video-warp"
                },
                [
                  _c("Xgplayer", {
                    attrs: { config: _vm.playerConfig },
                    on: {
                      player: function($event) {
                        return _vm.playInit($event)
                      }
                    }
                  }),
                  _c("div", { staticClass: "playing-video" }, [
                    _c("span", { staticClass: "playing-item" }, [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            width: "170px",
                            "white-space": "nowrap",
                            "text-overflow": "ellipsis",
                            overflow: "hidden"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.currentVideo.name) + " ")]
                      ),
                      _c("p", [_vm._v(_vm._s(_vm.currentVideo.duration))])
                    ]),
                    _vm.openList === false
                      ? _c("span", {
                          staticClass: "show-list-icon show-list-icon-top",
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.showList }
                        })
                      : _vm._e(),
                    _vm.openList === true
                      ? _c("span", {
                          staticClass: "show-list-icon show-list-icon-down",
                          on: { click: _vm.hideList }
                        })
                      : _vm._e()
                  ])
                ],
                1
              ),
              _c("transition", { attrs: { name: "list" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.openList,
                        expression: "openList"
                      }
                    ],
                    staticClass: "video-list-wrap",
                    style: {
                      "max-height": _vm.isPlayVideo == true ? "230px" : "350px"
                    }
                  },
                  _vm._l(_vm.watchListData, function(item, index) {
                    return _c(
                      "dl",
                      {
                        key: item.ip_id,
                        staticClass: "list-item",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.switchVideo(item, index)
                          }
                        }
                      },
                      [
                        _c("dd", { staticClass: "video-img-warp" }, [
                          _c("img", {
                            staticClass: "gif",
                            attrs: { myid: item.ip_id, src: item.cover_img }
                          }),
                          !item.cover_img
                            ? _c("i", { staticClass: "iconfont icon-shipin1" })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              class: index === _vm.currentIndex ? "active" : ""
                            },
                            [
                              index === _vm.currentIndex
                                ? _c("img", {
                                    staticClass: "video-status",
                                    attrs: {
                                      src: require("../assets/images/playing.png"),
                                      alt: ""
                                    }
                                  })
                                : _vm._e()
                            ]
                          )
                        ]),
                        _c("dt", [
                          _c("p", [_vm._v(_vm._s(item.name))]),
                          _c("p", [_vm._v(_vm._s(item.duration))])
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "watch-list-icon" }, [
            _c("span", {
              staticClass: "watch-img",
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.showWatchList }
            }),
            _vm.show
              ? _c("span", [
                  _vm._v(
                    "观看列表(" +
                      _vm._s(_vm.listNum == "" ? 0 : _vm.listNum) +
                      ")"
                  )
                ])
              : _vm._e(),
            _vm.show
              ? _c("span", {
                  staticClass: "to-watch",
                  staticStyle: { cursor: "pointer" },
                  on: { click: _vm.goToWatch }
                })
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }